.App-body {
  margin-bottom: 30px;
  margin-top: 20px;
}

.App-footer {
  text-align: right;
  font-size: 8px;
  color: #aaaaaa;
  margin: 20px 0px;
}

.navbar-renfe {
  background-color: var(--color-primary);
  color: #ffffff;
  padding: 10px 0px;
  margin-bottom: 20px;
}

.navbar-renfe a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-radius 0.15s ease-in-out;
}

.navbar-renfe a:hover {
  background-color: #fff;
  color: var(--color-primary);
  border-radius: 5px;
}

.btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.btn-primary:hover {
  background-color: #ffffff !important;
  color: var(--color-primary) !important;
}

.btn-terciary:hover {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #ffffff !important;
}

.btn-terciary {
  background-color: #ffffff !important;
  color: var(--color-primary) !important;
}

.para,
.ultima {
  font-weight: bold;
}

.para .horaSortida,
.para .nomParada {
  background-color: var(--color-primary);
  /* padding: 5px; */
  color: #ffffff;
}

.ultima .horaSortida {
  background: none !important;
}

.para .horaPas span {
  display: none;
}

.trenParadaEspecial div {
  font-weight: bold;
}

.dadesItinerari {
  margin-bottom: 20px;
}

.dadesItinerari .data {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 6px;
}

.fullHorariBotons {
  margin-bottom: 15px;
}

.numeroTren {
  font-size: 1.5em;
  font-weight: bold;
  background-color: var(--color-primary);
  color: #ffffff;
  display: inline-block;
  padding: 2px 5px;
  margin-left: -5px;
  margin-bottom: 5px;
}
.tipusTren {
  border: 1px #000000 solid;
  padding: 2px 5px;
}

.liniaTipusTren {
  margin: 10px 0px;
}

.PL {
  color: #aaaaaa;
  display: contents;
}

.gare {
  display: contents;
}

.noMostrarPL .parada .PL {
  display: none;
}

.pk {
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
}

.observacions {
  margin-top: 15px;
  font-style: italic;
}
.observacions h4 {
  font-size: 14px;
}
.observacio {
  font-size: 12px;
}

.remarca {
  font-style: italic;
}

.divTableBody .remarcaTaula {
  /* margin-left: 104%; */
  margin-bottom: 7px;
  display: block;
  padding: 2px 5px;
  background-color: #faf870;
  width: fit-content;
  font-style: italic;
  font-size: 10pt;
}

.parada,
.topTaula {
  text-align: center;
  font-size: 12pt;
}
.nomParada {
  text-align: left;
}

.logoRenfe {
  right: 0;
  max-width: 150px;
  display: inline-block;
  width: 100%;
  float: right;
  top: -60px;
  position: relative;
  margin-top: -50px;
}

.me-auto a {
  margin-right: 10px;
}

/* Velocitats mitjanes */
.quadreLila {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid var(--color-primary);
  font-size: 14px;
}

.quadreLila h4 {
  font-size: 16px;
}

.numeroVelocitatMitjana {
  font-weight: bold;
}

.quadreLila .descripcio {
  font-style: italic;
  margin-bottom: 0.4rem;
}

.llistaVelocitatsMitjanes {
  column-count: 2;
}

/* Taules */
.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  border: 0px solid #cacaca;
  display: table-cell;
  padding: 2px 5px;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

.taulaElements {
  display: contents;
}

.columnaEstacio {
  width: 40%;
}

/* Missatge Error */
.missatgeError {
  margin-top: 15px;
}

/* Llista Horaris */
.canviDataRow.row {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid var(--color-primary);
}

.canviDataCol {
  font-weight: bold;
  padding-top: 5px;
}

/* Notes */
span.preInlineBlock {
  position: relative;
  bottom: 9px;
}

.caixaEscriure {
  border: 1px solid var(--color-primary);
  width: 200px;
  height: 30px;
  display: inline-block;
}

.liniaNotes {
  border-bottom: 1px dotted var(--color-primary);
  height: 30px;
}

.margesNotes {
  margin-top: -10px;
  margin-bottom: 10px !important;
}

/* Per imprimir */
@media print {
  .dadesItinerari .data,
  .dadesItinerari .textItinerari,
  .liniaTipusTren {
    margin-left: 5px;
  }

  .numeroTren {
    margin-left: 0px;
  }

  .parada,
  .topTaula {
    text-align: center;
    font-size: 10pt;
  }

  .pk {
    padding-top: 5px;
    font-size: 8pt;
  }

  .quadreLila,
  .quadreLila .descripcio {
    font-size: 8pt;
    break-inside: avoid;
  }

  .quadreLila h4 {
    font-size: 10pt;
  }

  .divTableBody .remarcaTaula {
    font-size: 8pt;
  }
}

.unselectable {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

@media only screen and (max-width: 770px) {
  .llistaVelocitatsMitjanes {
    column-count: 1;
  }
}

@page {
  margin: 1cm;
}
