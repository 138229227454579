.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
.contenidorLector {
  margin: 0 auto;
  text-align: center;
}

.react-pdf__Page__svg {
  margin: 0 auto;
  max-width: 100% !important;
  height: 100% !important;
}

/* Protector pantalla */

.fonsProtegit {
  position: fixed;
  font-size: 50px;
  color: #9f9f9f5e;
  rotate: 20deg;
}

.protegit1 {
  top: 10%;
  left: 5%;
}

.protegit2 {
  top: 10%;
  left: 25%;
}

.protegit3 {
  top: 10%;
  left: 45%;
}

.protegit4 {
  top: 10%;
  left: 65%;
}

.protegit5 {
  top: 10%;
  left: 85%;
}

.protegit6 {
  top: 35%;
  left: 5%;
}

.protegit7 {
  top: 35%;
  left: 25%;
}

.protegit8 {
  top: 35%;
  left: 45%;
}

.protegit9 {
  top: 35%;
  left: 65%;
}

.protegit10 {
  top: 35%;
  left: 85%;
}
.protegit11 {
  top: 60%;
  left: 5%;
}

.protegit12 {
  top: 60%;
  left: 25%;
}

.protegit13 {
  top: 60%;
  left: 45%;
}

.protegit14 {
  top: 60%;
  left: 65%;
}

.protegit15 {
  top: 60%;
  left: 85%;
}

.protegit16 {
  top: 85%;
  left: 5%;
}

.protegit17 {
  top: 85%;
  left: 25%;
}

.protegit18 {
  top: 85%;
  left: 45%;
}

.protegit19 {
  top: 85%;
  left: 65%;
}

.protegit20 {
  top: 85%;
  left: 85%;
}

.logoRenfeProtegit {
  max-width: 150px;
  width: 100%;
  opacity: 0.2;
}

@media print {
  .pdf-container,
  .react-pdf__Document {
    display: none;
  }
}
